// --- GLOBAL VARIABLES --- //
var $window = $(window);
var $document = $(document);
var $header = $('.header');
var bpSmall = 771;
var bpMedium = 992;
var bpLarge = 1200;
var classActive = 'is-active';
var classDisabled = 'is-disabled';
var classLast = 'is-last';
var classHasError = 'has-error';
var classLoading = 'is-loading';
var classOpened = 'is-opened';
var classSelected = 'is-selected';
var classCollapse = 'is-collapse';
var classAnimated = 'is-animated';
var $this = null;
var $containers = $('html,body');

// --- COMPONENTS, PARTS --- //
$(document).ready(function() {
	// ---  COMPONENTS --- //
	
	// Google Maps
	componentGoogleMaps();
	
	// Navigation
	componentFullscreenNavigation();
	
	// Detect IE
	partDetectIE();

	// Scroll to
	partScrollTo();


	// Scroll Resize
	partScrollResize();
});

var resizeTimer;

// --- SCROLL EVENT --- //
$(document).scroll(function() {
	if (resizeTimer) {
		window.cancelAnimationFrame(resizeTimer);
	}

	resizeTimer = window.requestAnimationFrame(function () {
		partScrollResize();
	});
});

// --- RESIZE EVENT --- //
$(window).resize(function() {
	if (resizeTimer) {
		window.cancelAnimationFrame(resizeTimer);
	}

	resizeTimer = window.requestAnimationFrame(function () {
		partScrollResize();
	});
});

// --- LOAD EVENT --- //
$(window).bind('load', function() {
	// Dots
	partDots();

	// Page Loader
	$('body').addClass(classActive);

	if (window.location.hash && $('.page-index').length){
		var target = window.location.hash;

		$( 'html, body' ).stop().animate({
			scrollTop: $( target ).offset().top - 60
		});
	}
});
