//====================================================
//  Function: Scroll to 
//====================================================
function partScrollTo(){
	if( $( '.js-scroll-trigger' ).length ){
		$( '.js-scroll-trigger' ).bind( 'click' , function () {
			var inst = $(this);
			var target = $(this).attr( 'data-scroll' );
			var navigationHeight = $('.navigation').height();

			if (target.length && $('.page-index').length) {
				if($( '.navigation' ).hasClass( 'is-collapse' ) && $(window).width() < bpMedium ){
					$( '.navigation' ).removeClass( 'is-collapse' );
				}
				$( 'html, body' ).stop().animate({
					scrollTop: $( '#' + target + '' ).offset().top - 60
				});
				return false;
			}
			
		});
	}
}