//====================================================
//  Function: Scroll & resize events
//====================================================
var windowScrollTop;
var windowWidth;

if ($('.navigation').length){
	var navigation = $('.navigation');
}

function partScrollResize(){
	windowScrollTop = $(window).scrollTop();
	windowWidth = $(window).width();

	if ($('.navigation').length){
		if (windowScrollTop > 1 && windowWidth > bpMedium) {
			navigation.addClass( 'navigation-scroll' );
		} else {
			navigation.removeClass( 'navigation-scroll' );
		}
	}
}
